import * as React from "react";
import { FaWhatsapp } from "react-icons/fa";

import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "@reach/router";

const Component = () => {
  const pathname = useLocation().pathname;

  return (
    <>
      <AnimatePresence>
        {pathname === "/" && (
          <motion.div
            className="whatsapp-badge"
            initial={{ opacity: 0, bottom: -50 }}
            animate={{ opacity: 1, bottom: 25 }}
            exit={{ opacity: 0, bottom: -50 }}
            transition={{ duration: 0.4 }}
          >
            <a
              href="https://wa.me/+5553981551905"
              target="_blank"
              rel="noreferrer"
            >
              <div className="d-flex justify-content-center align-items-center h-100">
                <FaWhatsapp />
              </div>
            </a>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Component;
